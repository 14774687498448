import React, { useEffect, useState } from "react";
import '../styles/antd.css';
import '../styles/people.css';

import type { MenuProps } from 'antd';
import { AutoComplete, Button, Col, Collapse, Divider, Dropdown, Empty, Form, Input, Modal, Radio, Row, Tag, Typography, Tooltip, Popover, Spin, Select, Skeleton, Space, notification  } from 'antd';

import {CaretRightFilled, CheckCircleTwoTone, CheckOutlined, LoadingOutlined, MessageOutlined, PhoneOutlined, PlusOutlined, ShareAltOutlined, StarOutlined, StarFilled, SmileOutlined, UsergroupAddOutlined, MailOutlined, TeamOutlined, QuestionCircleTwoTone} from '@ant-design/icons';

import { firebase_database, firebase_instance, firestore } from "../utils/database";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import stage_dict from "../utils/stage";
import CreateMeeting from "../components/CreateMeeting";
import MessageList from "../components/people/MessageList";

import BuddyCall from "../components/people/BuddyCall";

import useReferredState from "../utils/useReferredState";
import sendEmail from "../utils/emailNotification";
import createSentMessage from "../utils/createSentMessage";
import { conf_ID, compareDocuments, getBaconDistance, getColleaguesSS, getExpertise, getInterest, getSimiliarUsers, getLikedContent, findMatchingName } from "../utils/userProfiling";
import JoinMeeting from "../components/JoinMeeting";
import SuggestionMaking from "../components/SuggestionMaking";

import computeQuery, {explainKeywords, explainResult} from "../utils/model";
import authorListFormat from "../utils/authorListFormat";

const { Panel } = Collapse;
const { Search } = Input;
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

// to store the list of users 
let originalUsers = [];

const matchingQuery = ["Who are experts who can teach me about my area of interest?", "Who can introduce me to", "Who are working on", "Who shares my interests?"]; // list of hardcoded queires

// Leaving these variable outside of components so the order doesn't change for every rendering
const options = [ {value: (intro, sim) => matchingQuery[0]}, 
    {value: (intro, sim) => <span>{matchingQuery[1]} {intro} ?</span>}, 
    {value: (intro, sim) => <span>Who has published in {sim} topics as me?</span>}, 
    {value: (intro, sim, topic) => <span>Who is working on {topic}?</span>}, 
    {value: (intro, sim) => matchingQuery[3]}, 
    {value: (intro, sim) => "Who should I meet to expand my network?"}
].sort( () => .5 - Math.random() ); // shuffle the order of buttons 

const colors =["#B37454", "#FF9D6B", "#B39B30", "#B3309E", "#5DB37C", "#AD9B9A"];



const People = () => {
    const auth = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [me, setMe] = useState({});
    const [wishUsers, setWishUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [statusList, setStatusList] = useState({});
    const [callStatusList, setCallStatusList] = useReferredState([]);
    const [interests, setInterests] = useState([]);
    const [relationships, setRelationships] = useState({});

    const [suggestions, setSuggestions] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [isBuddyVisible, setIsBuddyVisible] = useState(false);

    const [isSendingModalVisible, setIsSendingModalVisible] = useState(false);
    const [message, setMessage] = useState("Hi, I would love to have a chat with you! Please call me once you are available.");
    const [receiver, setReceiver] = useState("");

    const [searchLoading, setSearchLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [typingQuery, setTypingQuery] = useState(""); // the query that the user is typing
    const [isInvalidSearch, setInvalidSearch] = useState(false); // true when users type something irrelevant, this is different than when there is no match

    const [isSimilar, setIsSimilar] = useState("similar");
    const defaultTopicValue = ["data scientists", "VR", "misinformation"];
    const [topicSearch, setTopicSearch] = useState(defaultTopicValue[defaultTopicValue.length * Math.random() | 0]);

    const [suggestionForm] = Form.useForm();
    const [currentSuggestionUser, setCurrentSuggestion] = useState({});
    const [suggestionRecipients, setSuggestionRecipients] = useState([]);
    const [suggestionTopics, setSuggestionTopics] = useState([{"name": undefined, topics: ""}]);
    const [suggestionMade, setSuggestionMade] = useState({});

    const [sentMessages, setSentMessages] = useState([]);

    const [browserNotificationGranted, setBrowserNotificationGranted] = useState("default");
    
    const [talkEnabled, setTalkEnabled] = useState(true);
    const [hadChatWith, setHadChatWith] = useState({});

    // Which version of tags I'm seeing
    const [ABtest, setABtest] = useState(true);
    
    // whether or not if I'm junior
    let junior = true;

    const history = useNavigate();

    const searchError = {
        "NO_MATCH": "No matching Who2chat users", 
        "NO_OTHER_EXPERTISE": "The person you inquire did not have publications (or did not link their academic profile), so we can't answer this question. Please try the question for other person", 
        "NO_EXPERTISE": <span>You did not connect your academic profile, so we can't figure out your expertise. Go to the <a href="settings">Setting</a> to set your profile or ask another question!</span>,
        "NO_USER": "We are not able to find any Who2chat user of the given name. You can only search researchers registered in the system.", 
    }

	useEffect(() => {
        getUsers();
        getStatusList();
        getCallStatusList();

        // TODO if ACM token exists, fetch updated marked papers

        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification. Please use a different browser or check out this website once in a while to see if someone is trying to reach out to you!");
        } else {
            setBrowserNotificationGranted(Notification.permission);
        }

        // hack for status check
        makeUserOnline();

        // hack for playing sound initiated by user click (for Safari), so we can play noti sound later 
        const handleDocumentClick = (event) => {
            const sound = new Audio('data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV');

            const promise = sound.play();

            if (promise !== undefined) {
                promise.then(() => {}).catch(error => console.error);
            }
        };

        // Add event listener during the capturing phase
        document.addEventListener('click', handleDocumentClick, true);

        // Clean up the event listener
        return () => {
            document.removeEventListener('click', handleDocumentClick, true);
        };

    }, []);

    const makeUserOnline = async () => {
        // hack for status check
        const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
        userStatusDatabaseRef.set({
            state: 'online',
            updatedAt: Date.now(),
        });
    }

    const getStatusList = async () => {
        const ref = await firebase_database.ref(`/status`);
        const statusRef = await ref.get();
        const status = statusRef.val();

        setStatusList(status);

        ref.on("value", (snapshot) => {
            const newStatus = snapshot.val();
            setStatusList(newStatus);
        })
    }

    const getCallStatusList = async () => {
        const ref = firestore.collection("meeting");
        const snapshot = await ref.get();

        let newCallStatusList = snapshot.docs.map(doc => {
            const data = doc.data();
            return ({
                available: !(data.meetingNumber && data.meetingNumber.length > 0), 
                waiting: data.meetingNumber && data.meetingNumber.length > 0 && (data.status === "queue" || data.status === "host" || data.status === "guest"),
                meetingNumber: data.meetingNumber,
                id: doc.id,
            })
        });

        setCallStatusList(newCallStatusList);

        // listener for changes
        ref.onSnapshot(snapshot => {
            snapshot.docChanges().forEach(change => {
                const data = change.doc.data();
                // console.log(data);
                const listWithoutUser = callStatusList.current.filter(user => user.id !== change.doc.id);
                const newList = [...listWithoutUser, {
                    id: change.doc.id, 
                    meetingNumber: data.meetingNumber,
                    waiting: data.meetingNumber && data.meetingNumber.length > 0 && (data.status === "queue" || data.status === "host" || data.status === "guest"),
                    available: !(data.meetingNumber && data.meetingNumber.length > 0),
                }];

                setCallStatusList(newList);
            });
        });

    }

    const getUsers = async () => {
        // get all users except myself
        const snapshot = await firestore.collection('settings').get(); // load all the users in the system
        let newUsers = snapshot.docs.filter(doc => doc.id != auth.currentUser.uid) // exclude myself
                            .filter(doc => doc.data().name && doc.data().myLikeIds && doc.data().myLikeIds.length) // filter who set their name
                            .map(doc => {
                                return ({
                                    ...doc.data(), 
                                    id: doc.id,
                                })
                            });

        // if no setting set, redirect to Settings
        const currentUser = snapshot.docs.filter(doc => doc.id === auth.currentUser.uid);
        if (currentUser.length === 0) {
            history("/settings");
            return;
        }
        else {
            const currentUserData = currentUser[0].data();
            if(!currentUserData.verified || !currentUserData.name || !currentUserData.myLikeIds) {
                history("/settings");
                return;
            }
            
            // send them back to the setting page if they have less than two papers interest
            if(currentUserData.myLikeIds && currentUserData.myLikeIds.length < 2) {

                // fetch their liked papers just in case
                if(currentUserData.token) {
                    const my_liked = await getLikedContent(currentUserData.token);

                    let my_liked_ids = [];
                    if (my_liked['myLists'].length) {
                        my_liked_ids = my_liked['myLists'][0]['myListItems'].filter(p => p.conferenceId === conf_ID).map(p => p.contentId);
                    }
                
                    my_liked_ids = my_liked_ids.concat( my_liked['myScheduleSessions'].filter(p => p.conferenceId === conf_ID).map(p => p['myScheduleContents']).flat().map(p => p['contentId']) );

                    await firestore.doc(`settings/${auth.currentUser.uid}`).update({
                        myLikeIds: firebase_instance.firestore.FieldValue.arrayUnion(...my_liked_ids.map(id => ""+ id)), // make sure to save as string
                    })

                    // refresh to reset
                    if(new Set( currentUserData.myLikeIds.concat(my_liked_ids.map(id => ""+ id)) ).size >= 2)
                        window.location.reload();
                } 
                
                // if still less than 2 take them to the setting page
                if(currentUserData.myLikeIds.length < 2) {
                    history("/settings");
                    return;
                }
            }

            if (currentUserData.selectedInterests)
                setInterests(currentUserData.selectedInterests);

            setMe({...currentUser[0].data(), id: auth.currentUser.uid});
            getExpertise(currentUser[0].data());
            setABtest(parseInt(auth.currentUser.uid[auth.currentUser.uid.length-1]) % 2);
            junior = "selectedMatch" in currentUser[0].data() ? currentUser[0].data().selectedMatch.map(s => parseInt(s.publication_cnt)).reduce((partial_sum, a) => partial_sum + a,0)
            < 15: true;

            if (currentUser[0].data().relationship)
                setRelationships(currentUser[0].data().relationship);
        }

        // get wishlist users
        const w_snapshot = await firestore.collection('wishlist').doc(auth.currentUser.uid).get();
        const newWishUsers = (w_snapshot.data()) ? newUsers.filter(user => user.id in w_snapshot.data() && w_snapshot.data()[user.id]["wished"]) : [];

        setWishUsers(newWishUsers);
        
        // can't use isinwishlist() because wishUser is not set by the time calling this line
        originalUsers = [];
        if(newUsers.length) {
            originalUsers = getSimiliarUsers({...currentUser[0].data(), id: auth.currentUser.uid}, newUsers);
            originalUsers.sort((a, b) => a.name.localeCompare(b.name)) // sort by names
        }

        setUsers( originalUsers );
        

        // get suggested-to-meet users
        const s_snapshot = await firestore.collection('suggestion').doc(auth.currentUser.uid).get(); // load list of suggested people `auth.currentUser.uid)` to talk
        let newSuggestions = {};
        newUsers.filter(user => user.id in (s_snapshot.data() || {}))
            .map(user => {newSuggestions[user.id] = {
            "suggested_by": Object.keys(s_snapshot.data()[user.id]), 
            "topics": Object.keys(s_snapshot.data()[user.id]).map(k => s_snapshot.data()[user.id][k])
        }; return null;});
        setSuggestions(newSuggestions);

        // get suggestion made by this user
        const smade_snapshot = await firestore.collection('suggestion_made').doc(auth.currentUser.uid).get(); // load list of suggested made by `auth.currentUser.uid)`
        let newSuggestionsMade = {};
        newUsers.filter(user => user.id in (smade_snapshot.data() || {}))
            .map(user => {newSuggestionsMade[user.id] = smade_snapshot.data()[user.id]; return null;});
        setSuggestionMade(newSuggestionsMade);   
        
        
        let h = {};
        for (const user of [...newUsers,...wishUsers]) {
            try {
                let a = [];// list of people this user havemet
                if(user.met)
                    a = user.met.filter(uid => {
                        // if this is user themselves don't count
                        if([user.id,  auth.currentUser.uid].includes(uid)) return false;
                        return true;
                    }
                ).map(uid => isJunior(uid))  
                h[user.id] = {junior: a.filter(a => a == true).length > 0, senior: a.filter(a => a == false).length > 0}
              } catch (error) {
                  console.log(error);
                h[user.id] = {junior: false, senior: false}
              }
          }
          
        setHadChatWith(h);

        const messagesRef = await firestore.collection("message")
            .where(
                "sender",
                "==",
                auth.currentUser.uid
            )
            .get();

        const sentMessages = messagesRef.docs.map(doc => {
            return {
                ...doc.data(),
                id: doc.id,
            }
        });

        setSentMessages(sentMessages);

        setLoading(true);

        makeUserOnline();
    }

    const logHelper = (eventType, target_user, target_user_id = null) => {
        let tag_lists = [];
        let is_suggested = (u)=> u.id in suggestions && Object.entries(suggestions[u.id]).filter(([k, v]) => Object.keys(v).length).length > 0;

        if(target_user_id) {
            target_user = users.filter(user => user.id === target_user_id)[0];

            // what is common keyword 
            tag_lists = [...tag_lists, ...target_user.commonTerms];
        }
            

        // whether they got a peer suggestion on this person
        if( is_suggested(target_user) )
            tag_lists.push("suggested");

        

        if( me.wantsToMeet && me.wantsToMeet.includes(target_user.id) )
            tag_lists.push("wants to meet");

        const status = (statusList && target_user.id in statusList) ? statusList[target_user.id].state : "offline";
        const isOnline = (status === "online");
        if(isOnline){
            if(ABtest) tag_lists.push("Happy to chat");
            else {
                if(target_user.selectedMatch && target_user.selectedMatch.length) {
                    if(target_user.id in hadChatWith? hadChatWith[target_user.id][junior?"junior":"senior"]:false)
                        tag_lists.push("someone like u");
                }
            }
        }

        document.dispatchEvent(new CustomEvent(eventType, { detail: {
            tags: tag_lists, // list of tags present at this user 
            added_user: target_user.id
        }}));
    }

    const addToList = async (e) => {
        e.stopPropagation();

        // uid cannot be sent as parameter
        const uid = e.currentTarget.id.substring(6,);
        const new_user = users.filter(user => user.id === uid); // the new user who is added

        await firestore.collection('wishlist').doc(auth.currentUser.uid).set({[uid]: {"wished": true}}, { merge: true });
        const newWishUsers = [
            ...wishUsers, // another users in wishlist
            ...new_user 
        ];
        
        setWishUsers(newWishUsers);

        logHelper("addToWishlist", new_user[0]);
    }

    const removeFromList = (e) => {
        e.stopPropagation();

        // uid cannot be sent as parameter
        const uid = e.currentTarget.id.substring(6,);

        // Don't delete the note but just set the boolean to false
        firestore.collection('wishlist').doc(auth.currentUser.uid).update({[uid]: {"wished": false}});

        const newWishUsers = wishUsers.filter(user => user.id !== uid);
        setWishUsers(newWishUsers);

        logHelper("removeFromWishlist", wishUsers.filter(user => user.id == uid)[0]);
    }
    
    const filterUsers = (txt) => {
        alert("todo; filter by name, interest, paper title etc");
    }

    const suggestBtn = (user) => <Tooltip title={`Suggest meeting ${user.name} to your colleagues or students!`}><Button type="default" className="suggest" onClick={e => {e.stopPropagation();showModal(user)}}>
        <span><SmileOutlined /> <CaretRightFilled /> <SmileOutlined /></span>
    </Button></Tooltip>;

    const genExtra = (user, isOnline, isAvailable, isWaiting, roomId) => (
        <>
        {suggestBtn(user)}
        {(!talkEnabled && isOnline) ? (<Tooltip title="This will be enabled once the social hour starts!"><Button type="primary" disabled={true}><PhoneOutlined /> Start a call </Button></Tooltip>):
            (isOnline && isAvailable) && <CreateMeeting myId={auth.currentUser.uid} userId={user.id} setIsSendingModalVisible={setIsSendingModalVisible} setReceiver={setReceiver} logHelper={logHelper}/>}
        {(talkEnabled && isOnline && !isAvailable && !isWaiting && roomId ) && <JoinMeeting roomId={roomId} user={user} logHelper={logHelper}/>} {/* request to join a room */}
        {(!isOnline || isWaiting) && <Tooltip placement="right" title="Leave a message"><Button type="ghost" onClick={(e) => {setReceiver(user.id);showSendingModal(e);}}><MessageOutlined /></Button></Tooltip>} {/* leave a message */}
        </>
    );

    const showSendingModal = (e) => {
        e.stopPropagation();
        setIsSendingModalVisible(true)
    }

    const showModal = (user) => {
        setCurrentSuggestion(user);
        setIsModalVisible(true);
    };

    const cancelSuggestion = (suggested, suggested_to_id) => {
        // console.log(suggested, suggested_to_id);
        var batch = firestore.batch();

        let suggestionRef = firestore.collection('suggestion').doc(suggested_to_id);
        batch.update(suggestionRef, {
            [`${suggested.id}.${auth.currentUser.uid}`]: firebase_instance.firestore.FieldValue.delete()
        }, {merge: true});
        console.log(`${suggested.id}.${auth.currentUser.uid}`);

        let suggestionMadeRef = firestore.collection('suggestion_made').doc(auth.currentUser.uid);
        batch.set(suggestionMadeRef, {
            [suggested.id]: firebase_instance.firestore.FieldValue.arrayRemove(suggested_to_id)
        }, {merge: true});

        batch.commit();


        let newsuggestionMade = {...suggestionMade};
        newsuggestionMade[suggested.id] = suggestionMade[suggested.id].filter(function(user) {
            return user.id !== suggested_to_id
        })
        setSuggestionMade(newsuggestionMade);  
    }

    const handleOk = (e) => {
        // save the suggested user in db
        setIsModalVisible(false);

        suggestionForm.resetFields();

        // alert(`${auth.currentUser.uid} suggests ${suggestionRecipients.map(u => u.name).join()} to meet ${currentSuggestionUser.name} to talk about ${suggestionTopics}`);

        // Initializing a write batch...
        var batch = firestore.batch();
        

        suggestionRecipients.map(u => {
            var recipientRef = firestore.collection("suggestion").doc(u.id);
            batch.set(recipientRef, {[currentSuggestionUser.id]: {[auth.currentUser.uid]: suggestionTopics}}, {merge: true});
            // console.log(currentSuggestionUser);

            // also record suggestion_made
            var suggestionAuthorRef = firestore.collection('suggestion_made').doc(auth.currentUser.uid);
            batch.set(suggestionAuthorRef, {[currentSuggestionUser.id]: firebase_instance.firestore.FieldValue.arrayUnion(u.id)}, {merge: true});

            return null;
        })

        batch.commit();
        
        // update suggestionMade to rerender 
        let newsuggestionMade = {...suggestionMade};
        newsuggestionMade[currentSuggestionUser.id] = [... new Set( // remove dup
            (suggestionMade[currentSuggestionUser.id] || []).concat(suggestionRecipients.map(s => s.id)) )]; 
        setSuggestionMade(newsuggestionMade);  

        document.dispatchEvent(new CustomEvent("sendSuggestion", { detail: {
            suggested_users: suggestionRecipients.map(s => s.id),
            topics: suggestionTopics,
            target_user: currentSuggestionUser.id
        }}));

        suggestionRecipients.forEach(function(u) {
            // send email notification to the receiver
            sendEmail(`New recommendation made by ${me.name}`, `${me.name} just made a suggestion to you. Check out their suggestion on Who2chat!`, u);
        }, this);

        
    };

    const handleCancel = () => {
        setIsModalVisible(false);

        suggestionForm.resetFields();
    };

    

    const handleGroupChange = async (values, user) => {
        let r = {...relationships};

        // if there was a custom relationship, add that into the list of relationships on db
        values.forEach(v => {
            if(v in relationships) return;
            else r[v] = [];
        })

        Object.keys( r ).forEach(g => {
            if(values.includes(g)) {
                r[g].push(user.id);
                r[g] = [...new Set(r[g])];
            } else {
                r[g] = r[g].filter(u => u != user.id);
            }
        })

        await firestore.collection("settings").doc(auth.currentUser.uid).set({
            relationship: r
        }, {merge: true});

        // update the state
        setRelationships(r);
    }

    const isJunior = (uid) => {
        const u = auth.currentUser.uid === uid? me
            :users.filter(x => x.id === uid).length ? users.filter(x => x.id === uid)[0] : wishUsers.filter(x => x.id === uid)[0];

        if (!(u && "selectedMatch" in u)) return false;
        return u.selectedMatch && u.selectedMatch.map(s => parseInt(s.publication_cnt)).reduce((partial_sum, a) => partial_sum + a,0)
            < 15;
    }

    const renderUser = (user, index, isWishList) => {
        const isMe = me.id === user.id
        const status = (statusList && user.id in statusList) ? statusList[user.id].state : "offline";
        const callStatus = callStatusList.current.filter(s => s.id === user.id);
        const isOnline = (status === "online");
        const isAvailable = (callStatus.length === 0 || (callStatus.length > 0 && callStatus[0].available));
        const isWaiting = (callStatus.length > 0 && callStatus[0].waiting);
        const roomId = (callStatus.length > 0) ? callStatus[0].meetingNumber : "";

        return (
            
            <Panel 
                id={`${user.id}`}
                header={
                    <span>
                        <>
                            { isWishList ?
                                <Tooltip title="Remove this user from bookmark">
                                    <Button type="link" id={`entry-${user.id}`} className="anticon" onClick={e => removeFromList(e)}><StarFilled /></Button>
                                </Tooltip>
                            :
                                <Tooltip title="Bookmark this user">
                                    <Button type="link" role="button" id={`entry-${user.id}`} className="anticon" onClick={e => {e.stopPropagation();isMe ? alert("You can curate your wishlist of people to talk to based on shared interests and suggestion made by others!"):addToList(e)}}><StarOutlined /></Button>
                                </Tooltip>
                                 
                            }
                            <Tooltip title={isMe || isOnline ? "online": "offline"}><span className={`circle ${isMe || isOnline ? "green": "gray"}`}></span></Tooltip>
                            <b className="username">{["Soya", "David", "Sue", "Joshua"].includes(user.name.split(" ")[0]) ? user.name:user.name.split(' ').map(word => word[0]).join(' ')}{isMe ? " (me)":""}</b>, {stage_dict[user.stage]} at {["Soya", "David", "Sue", "Joshua"].includes(user.name.split(" ")[0]) ? user.affiliation: "---"}&nbsp; 
                        </>
                        {me.colleagues && me.colleagues.includes(user.id) && <Tooltip title="My colleague"><CheckCircleTwoTone /> </Tooltip>}
                        {isMe || (user.id in suggestions) && (suggestions[user.id]["suggested_by"].length > 0) && <Tooltip title="Your colleague suggested that you reach out to (or meet) this person"><Tag color="purple">Suggested by your colleague</Tag></Tooltip>}
                        {isMe || (me.wantsToMeet && me.wantsToMeet.length > 0 && me.wantsToMeet.includes(user.id)) && <Tooltip title="They left a message that they want to talk to you"><Tag color="orange">Wants to meet you</Tag></Tooltip>}
                        {isMe || sentMessages.filter(m => m.receiver === user.id).length > 0 && <Tooltip title="You left them a message to indicate your interest of meeting"><Tag color="grey"><MessageOutlined/> sent</Tag></Tooltip>}
                        {!isMe && searchQuery && (!user.queryResult? <Skeleton active />:<span><br/>{user.queryResult}</span>)}
                    </span>
                } 
                key={index} 
                extra={isMe ? 
                    <>
                        <Button type="primary" onClick={e => {e.stopPropagation();setIsBuddyVisible(true);}}>
                            <TeamOutlined /> Organize a buddy call
                        </Button>
                    </>
                    : genExtra(user, isOnline, isAvailable, isWaiting, roomId)}
            >

                { user.website &&
                    <>
                    <div className="">
                        <a href={user.website} target="_blank">{user.website}</a>
                    </div>
                    </>
                }

                {"selectedInterests" in user ? <h4 className="text-muted"><b>{user.selectedInterests.join(", ")}</b></h4>: ""}
                {"commonTerms" in user && (user.author == "-1") && (!user.prev_papers || Object.keys(user.prev_papers).length === 0) ? <h4 className="text-muted">Keywords of common interests: <b>{user["commonTerms"].join(", ")}</b></h4>: ""}
                
                {/* <h4 className="text-muted">CSCW '22 publications:</h4>
                <div className="indent">
                    {!("author" in user && user.author in proceeding["authors"]) ?
                        "No paper at this conference"
                        : proceeding["papers"].filter(p => p["authors"].includes(user.author)).map((p, index) => 
                            <p key={index}>
                                <b>{p["title"]}</b> <br/>
                                {p["authors"].map((a, i) => {
                                    const author = users.filter(u => (user.author !== u.author) && (u.author === a));
                                    return( author.length ?
                                        <a key={i} style={{color: "blue", textDecoration: "underline"}} href={`#${author[0].id}`}>{author[0]["name"]} ({author[0]["affiliation"]}),</a>
                                        : 
                                        `${proceeding["authors"][a].split(": ")[0]} (${proceeding["authors"][a].split(": ")[1]}), `
                                    )
                                })}
                            </p>)}
                </div> */}

                <h4 className="text-muted">Recent publications:</h4>
                <div className="indent">
                    {!user.prev_papers || Object.keys(user.prev_papers).length === 0 ?
                        "No previous publication"
                        :
                        Object.values(user.prev_papers).sort((a, b) => b.year - a.year).slice(0,5)
                        .map((v, index) => 
                        <p key={index}>
                            <b>{v["title"]}</b> {v["conference"]} {v["year"]} {v["abstract"] && <Popover content={<div style={{maxWidth: "600px"}}>{v["abstract"]}</div>}>
                                <Text mark>abstract</Text>
                            </Popover>}<br/>
                            {v["authors"].slice(0, 7).map((a,i) => {
                                const author = users.filter(u => (u.id != user.id) && (u.selectedMatch && u.selectedMatch.map(uu => uu.id).includes(a.authorId)));
                                return author.length ? 
                                    <a key={i} style={{color: "blue", textDecoration: "underline"}} href={`#${author[0].id}`}><small>{author[0]["name"]} ({author[0]["affiliation"]}),</small></a>:
                                    <small>{authorListFormat(v["authors"], i)} </small>})}
                        </p>
                        // .filter((v, index) => (user.prev_papers[v]["conference"] != "Proceedings of the ACM on Human-Computer Interaction") && (user.prev_papers[v]["year"] == 2021))
                        
                    )}
                </div>

               
                    {!isMe && (user.id in suggestions && suggestions[user.id]["suggested_by"].length ?
                    // someone suggested to meet this `user`
                    <><h4 className="text-muted">Suggested by your colleague</h4>
                    <div className="indent">
                    {/* <b>The following people suggested you to meet {user.name} and talk about:</b> */}
                    <ul>
                            {suggestions[user.id]["suggested_by"].map((s, index) => 
                                <span>{users.filter(u => u.id === s).map(u => u["name"]).join()} suggested You <CaretRightFilled /> {user.name} ️{": " + suggestions[user.id]["topics"][index]}</span>
                            )}
                    </ul></div>
                    </>
                    : "")}
                    {false && <>
                    <b>{user.name} looks relevant to your colleagues/students? Introduce them to each other:</b>
                    <br/>
                    <b style={{width:"200px", float: "left"}}>Martin Nisser: </b><span>topics</span>
                    <SuggestionMaking users={users} suggesteeId={user.id} setSuggestion={setSuggestion} suggestion={suggestionTopics} saveSuggestion={handleOk}/>
                </>}

                {/* <b>You suggested the following people to meet {user.name}:</b> */}
                {!isMe && <><h4 className="text-muted">🆘 Help {me.colleagues && me.colleagues.filter(c => user.id != c && originalUsers.map(o => o.id).includes(c)).map(uid => originalUsers.filter(o => o.id == uid)[0].name).concat(me.stage == "faculty" ? " your students" : " your peers").filter(c => c && c.trim().length).join(", ")} to enrich their network by introducing them! <Tooltip title={"Our finding suggests researchers benefit from peer recommendation. Introduce " + user.name +" to your colleagues who you found relevant to your colleagues"}><QuestionCircleTwoTone /></Tooltip> {suggestBtn(user)}</h4>
                    <ul>
                    {suggestionMade[user.id] && suggestionMade[user.id].length ? suggestionMade[user.id].filter(recipient => users.filter(u => u.id === recipient).length)
                        .map(recipient => 
                        <>You suggested: <Tag closable onClose={(e) => cancelSuggestion(user, recipient)}>
                            {users.filter(u => u.id === recipient)[0].name}
                        </Tag> <CaretRightFilled/>️ {user.name}<br/></>
                    ): <small>You didn't suggest {user.name} to anyone.</small>}
                    </ul></>}

                    {false && <>
                    <Select 
                            mode="tags" 
                            placeholder="Introduce to.."
                            value={relationships ? Object.keys( relationships ).filter(r => relationships[r].includes(user.id)) : ""}
                            onChange={(e) => handleGroupChange(e, user)}
                            style={{ width: 320 }}
                        >          
                            <Option key="student" value="student">My student</Option>
                            <Option key="colleague" value="colleague">My colleague</Option>
                            {relationships && Object.keys( relationships ).filter(r => !["student", "colleague"].includes(r)).map((r) => 
                                <Option key={r} value={r}>
                                    {r}
                                </Option>
                            )} 
                            <Option value="disabled" disabled>
                                <PlusOutlined/> or type a new group name to create a customized group
                            </Option>
                        </Select><span style={{color:"grey"}}> You suggested meeting {user.name} to {[].length} people!</span></>}

                    <Divider/>
            </Panel>
        )
        
    }

    const setSuggestion = (s) => {
        setSuggestionTopics(s);
    }

    const onSearchClear = (value: string) => {
        if(isInvalidSearch) setInvalidSearch(false)

        setUsers(originalUsers);
        setSearchQuery("");
    }

    const onSearchChange = (value: string) => {
        console.log(value);
        setTypingQuery(value);

    } 

    const onEnter = (e) => {
        onSearch(e.target.value);
    }

    const onSearch = async (value: string) => {
        // if user clear the search term, no-op
        if(value == "") return;

        // show loading indicator
        setSearchLoading(true);

        let u = originalUsers;

        // see if the query is one of prebuilt one
        let instructions;
        let func, attr1, attr2, negate;

        if(value.startsWith(matchingQuery[0]))    {
            instructions = "getSimiliarity | my.interest | expertise |";
            [func, attr1, attr2, negate] = instructions.split("|");
        } else if(value.startsWith(matchingQuery[1])) {
            const target_name = value.replace(matchingQuery[1], "").replace("?", "").trim();

            instructions = `getNetworkDistance | ${target_name} |  |`;
            [func, attr1, attr2, negate] = instructions.split("|");
            
        } else if(value.startsWith(`${matchingQuery[2]} similar topics`)) {
            instructions = "getSimiliarity | my.expertise | expertise |";
            [func, attr1, attr2, negate] = instructions.split("|");
        } else if(value.startsWith(`${matchingQuery[2]} complementary topics`)) {
            instructions = "getSimiliarity | my.expertise | expertise | not";
            [func, attr1, attr2, negate] = instructions.split("|");
        } else if(value.startsWith(matchingQuery[3])) {
            instructions = "getSimiliarity | my.interest | interest |";
            [func, attr1, attr2, negate] = instructions.split("|");
        } else if(value.startsWith("Who should I meet to expand my network?")) {
            instructions = "getNetworkDistance | me |  | not";
            [func, attr1, attr2, negate] = instructions.split("|");
        } else {
            // Request to the language model    
            // get response from language model 
            instructions = await computeQuery(value);
            [func, attr1, attr2, negate] = instructions.split("|");
        }
        

        console.log(instructions);

        if(func.trim() == "N/A") {
            setInvalidSearch(true);
            u = [];
        } else {
            setInvalidSearch(false);
            if(func.trim() == "getSimiliarity") {
                let myDocument, others = [], others_documents = [], targetUser = null;
                if(attr1.trim().split(".")[1] == "expertise") {
                    if(attr1.trim().split(".")[0] == "my") 
                        myDocument = getExpertise(me);

                    else {
                        targetUser = findMatchingName(attr1.trim().split(".")[0], u); 
                        myDocument = getExpertise(targetUser);
                    }

                } else { // interest
                    if(attr1.trim().split(".")[0] == "my") 
                        myDocument = getInterest(me);       

                    else {
                        targetUser = findMatchingName(attr1.trim().split(".")[0], u); 
                        myDocument = getInterest(targetUser);
                    }
                }

                for(const user of u) {
                    if(targetUser && targetUser.id == user.id) continue;
                    
                    const ex = attr2.trim() == "expertise"? getExpertise(user) : getInterest(user);

                    if(!ex.length) continue;

                    others.push(user);
                    others_documents.push(ex);
                }

                if(myDocument)  u = compareDocuments(me, myDocument, others, others_documents);
                else u = [];
                    

                if(negate.length) u.reverse()

                // for first 10 users, summarize their document using API  
                u = [...u.slice(0, 10)];
                let userPapers = [];
                
                for(const [index, user] of u.entries()) {
                    const papers = others_documents[others.map(o => o.name).indexOf(user.name)].split("|||")

                    let to_summarized = [];
                    let wc = 0;
                    for(const paper of papers) {
                        if(wc + paper.split(' ').length > 2900) break;

                        wc += paper.split(' ').length;
                        to_summarized.push(paper)
                    }

                    userPapers.push(to_summarized);
                }

                // load response from pre-populated response 
                let isComplete = true;
                const cp = u;
                u = [...u]
                for(const [index, user] of cp.entries()) {
                    const resultref = await firestore.collection("prepopulatedQuery")
                        .doc(user.id)
                        .get();

                    if(!resultref.exists) {
                        isComplete = false;
                        continue;
                    }

                    const result = resultref.data();

                    u[index]['queryResult'] = (attr2.trim() == "expertise" ? "Worked on " + result['expertise'] : "Summary of papers the user is interested in: " ) + result['interest'];
                    
                    
                }

                setUsers([...u]);
                if(!searchQuery)
                    setSearchQuery(value);

                if(!isComplete) {
                    // load results fo the rest of users 
                    for(const [index, user] of u.entries()) {
                        if(user['queryResult'] && user['queryResult'].trim().length) continue;
                        
                        const result = await explainResult(userPapers[index]);

                        u[index]['queryResult'] = result.length > 1 ? ((attr2.trim() == "expertise" ? "Worked on " : "Summary of papers the user is interested in: " ) + result) : " "; 

                        setUsers([...u]);
                    }

                }
                setSearchLoading(false);
                

                // code for running when open AI is reliable
                // send 3 request at a time for quick response
                // Promise.all(userPapers.slice(0, 3).map(p => explainResult(p))).then((values) => {
                //     for(const [index, v] of values.entries()) {
                //         u[index]['queryResult'] = v.length > 1 ? ((attr2.trim() == "expertise" ? "Worked on " : "Interested in " ) + v) : " "; 
                //     }
                    
                //     setUsers([...u]);
                //     if(userPapers.length <= 3)
                //         setSearchLoading(false);

                //     Promise.all(userPapers.slice(3).map(p => explainResult(p))).then((values) => {
                //         for(const [index, v] of values.entries()) {
                //             u[index+3]['queryResult'] = (attr2.trim() == "expertise" ? "Worked on " : "Interested in " )+ v; 
                //         }
                        
                //         setUsers([...u]);
                //         setSearchLoading(false);
                        
                //       });

                //   });


            } else if(func.trim() == "getNetworkDistance") {
                let targetUser = null;
                if(attr1.trim() == "me") {
                    targetUser = me;

                } else { // another user 
                    targetUser = findMatchingName(attr1.trim(), u);    
                }

                if(targetUser) {
                    const ocRef = await firestore.collection("network").doc(targetUser.id).get();
                
                    const myColleagues = ocRef.data();
    
                    const us = u;
                    u= [];
                    for(const user of us) {
                        const r = await getBaconDistance(targetUser, myColleagues, user);
                        const [dis, coauthorSSIDs] = r;
                        let names = [];
                        let queryResult = "";

                        if(dis == 1) queryResult = targetUser == me ? " ":`${user.name} worked with ${targetUser.name}.`;
                        else if(dis == 2) queryResult = `${user.name} worked with ${targetUser == me ? "your":(targetUser.name+"'s")} collaborators, ${coauthorSSIDs.map(c => c['name']).join(", ")}.`;
                        else if(dis == 3) queryResult = `${user.name} worked with ${coauthorSSIDs.map(c => c['name']).join(", ")}, who worked with ${targetUser == me ? "your":(targetUser.name+"'s")} collaborators.`;
                        else if(dis == 4) queryResult = `${user.name}'s collaborators worked with ${coauthorSSIDs.map(c => c['name']).join(", ")}, who also worked with ${targetUser == me ? "your":(targetUser.name+"'s")} collaborators.`;
                        else { queryResult = " "}

                        u.push( {...user, dis: dis, queryResult: queryResult} );
                    }
    
                    u = u.filter((user) => user.id != targetUser.id).filter((user) => user.dis).sort((a, b) => a.dis - b.dis);
                    
                    // if person with far from my network
                    if(negate.length) {
                        u = u.filter((user) => user.dis > 2);
                        u.reverse();
                    }
                    else {
                        u = u.filter((user) => user.dis < 5);
                    }
                } else {
                    setInvalidSearch(true);
                    u = [];
                }

                setSearchLoading(false);
            } else { // find keywords 
                const us = u;
                u= [];
                us.forEach(function(user, index) {
                    const userDocument = attr1.trim() == "expertise"? getExpertise(user) : getInterest(user);

                    if(!userDocument.length) return;

                    

                    const counts = attr2.split(",").map(k => userDocument.toLowerCase().split( k.replaceAll('"', '').toLowerCase() ).length - 1)
                    u.push( {...user, search_val: counts.reduce((partialSum, a) => partialSum + a, 0)} );
                }, this); 



                if(negate.length) {
                    u = u.filter((user) => !user.search_val).sort((a, b) => a.name.localeCompare(b.name));
                } else
                    u = u.filter((user) => user.search_val) // this would delete the ones with 0 or false
                        .sort((a, b) => b.search_val - a.search_val)

                // for first 10 users, summarize their document using API  
                u = u.slice(0, 10)
                let userPapers = [];

                if(!u.length) setSearchLoading(false);
                
                for(const [index, user] of u.entries()) {
                    const papers = (attr1.trim() == "expertise"? getExpertise(user) : getInterest(user)).split("|||")

                    let to_summarized = [];
                    let wc = 0;
                    for(const paper of papers) {
                        if(wc + paper.split(' ').length > 2900) break;

                        wc += paper.split(' ').length;
                        to_summarized.push(paper)
                    }


                    userPapers.push(to_summarized);
                }

                // send 3 request at a time for quick response
                Promise.all(userPapers.slice(0, 3).map(p => explainKeywords(p, attr2))).then((values) => {
                    for(const [index, v] of values.entries()) {
                        u[index]['queryResult'] = v; 
                    }
                    
                    setUsers([...u]);
                    if(userPapers.length <= 3)
                        setSearchLoading(false);

                    Promise.all(userPapers.slice(3).map(p => explainKeywords(p, attr2))).then((values) => {
                        for(const [index, v] of values.entries()) {
                            u[index+3]['queryResult'] = v; 
                        }
                        
                        setUsers([...u]);
                        setSearchLoading(false);
                        
                      });

                  });

                
            }

            
            
        }

        
        setUsers(u);
        setSearchQuery(value);
        

        // save the user query and matching query to DB
        const ref = firestore.collection("query").doc();
        
        ref.set({
            query: value,
            instructions: instructions,
            uid: auth.currentUser.uid,
            date: new Date()
        });
    }

    const searchSelected = (e) => {
        console.log(e.target.innerHTML);

        // to prevent trigerring by dropdown
        if(e.target.classList.contains("ant-select-item-option-content") || 
            e.target.classList.contains("ant-input")) return;

        if([...e.target.childNodes].filter(c => c.classList && c.classList.contains('ant-select')).length) {
            const introducer = [...e.target.childNodes].filter(c => c.classList && c.classList.contains('ant-select'))[0].outerText;
            setTypingQuery("Who can introduce me to " + introducer);
            onSearch("Who can introduce me to " + introducer);
        } else if([...e.target.childNodes].filter(c => c.classList && c.classList.contains('ant-radio-group')).length) {
            setTypingQuery(`${matchingQuery[2]} ${isSimilar} topics with me?`);
            onSearch(`${matchingQuery[2]} ${isSimilar} topics with me?`);
        } else if(e.currentTarget.innerHTML.includes("Who has published in")) {
            setTypingQuery(`${matchingQuery[2]} ${e.currentTarget.querySelector('input:checked').value} topics as me?`);
            onSearch(`${matchingQuery[2]} ${e.currentTarget.querySelector('input:checked').value} topics as me?`);
        } else if([...e.target.childNodes].filter(c => c.classList && c.classList.contains('ant-input')).length) {
            setTypingQuery(`Who are working on ${topicSearch}?`);
            onSearch(`Who are working on ${topicSearch}?`);
        } else if(e.target.innerHTML.includes('class="ant-input"')) { 
            // no-op, this triger when user types space in "who is working on "
            
        } else {
            setTypingQuery(e.target.innerHTML.replace("<span>", "").replace("</span>", ""));
            onSearch(e.target.innerHTML.replace("<span>", "").replace("</span>", ""));
        }
        
        
    }

    const introSelect = () => {
        return <Select
            defaultValue={originalUsers.length && originalUsers.filter(user => user.selectedMatch && user.selectedMatch.length)[0].id}
            style={{ width: 150 }}
            id="introOptions"
            onClick={e => e.stopPropagation()}
            onChange={(value) => {
                setTypingQuery("Who can introduce me to " + originalUsers.filter(u => u.id === value)[0].name);
                onSearch("Who can introduce me to " + originalUsers.filter(u => u.id === value)[0].name);
                }}
            options={
                originalUsers.filter(user => user.selectedMatch && user.selectedMatch.length).map(user => {
                    return { value: user.id, label: user.name };
                })
                }
        />;
    }

    const similiarSelect = () => {
        return <Radio.Group defaultValue={"similar"} onChange={(e) => {
            e.stopPropagation();
            setTypingQuery(`${matchingQuery[2]} ${e.target.value} topics as me?`);
            onSearch(`${matchingQuery[2]} ${e.target.value} topics as me?`);
            setIsSimilar(e.target.value);
            }} onClick={(e) => e.stopPropagation()}>
            <Radio value={"similar"} style={{color:"white"}} onClick={(e) => e.stopPropagation()}>similar</Radio>
            <Radio value={"complementary"} style={{color:"white"}} onClick={(e) => e.stopPropagation()}>complementary</Radio>
        </Radio.Group>;
    }

    const topicInput = () => {
        return <Input style={{ width: '130px' }} defaultValue={topicSearch} onChange={(e) => setTopicSearch(e.target.value)} onPressEnter={(e) => {
            e.stopPropagation();
            setTypingQuery(`Who are working on ${e.target.value}?`);
            onSearch(`Who are working on ${e.target.value}?`);
            setTopicSearch(e.target.value);
        }}/>;
    }

    const sortUsers = (users) => {
        return users.map((user, index) => 
            renderUser(user, index, wishUsers.filter(u => u.id === user.id).length > 0))    
    }

    const handleChange = (e) => {
        setMessage(e.currentTarget.value);
    }

    const sendMessage = async () => {
        createSentMessage(message, receiver, auth.currentUser.uid, auth.currentUser.displayName);
        
        setIsSendingModalVisible(false);

        notification.open({
            message: "Success",
            description: "Successfully sent!"
        }) 

        const receiverData = users.filter(u => u.id == receiver)[0];

        // send message to the receipient that they received a message
        sendEmail(`New message from ${me.name}`, `${me.name} sent you a message on Who2chat. Check out their message now!`, receiverData);

        setSentMessages([...sentMessages, {receiver: receiver}]);
    }

    const handleBrowserNotification = (perm) => {
        if(perm === "granted") {
            // they accept the noti! hide the notibuttion
            var notification = new Notification(`We will send you notification like this when someone tries to reach out to you!`);
        } else if(perm === "denied") {

        }else {
          // show that they should accept the notification
        }

        setBrowserNotificationGranted(perm);
        // alert( perm)
    }

    const enableBrowserNotification = () => {
        try {
            Notification.requestPermission()
                .then((p) => handleBrowserNotification(p))                                                                                                                                               
        } catch (error) {
            // Safari doesn't return a promise for requestPermissions and it                                                                                                                                       
            // throws a TypeError. It takes a callback as the first argument                                                                                                                                       
            // instead.
            if (error instanceof TypeError) {
                Notification.requestPermission((p) => {                                                                                                                                                             
                    handleBrowserNotification(p);
                });
            } else {
                throw error;                                                                                                                                                                                       
            }                                                                                                                                                                                                      
        }     
    }
    

    return  (  
    <Row className="people">
    
    <MessageList isPeopleTab={true} />

    {/* Render when some essential data are loaded */}
    {loading ? 
    <Col md={24} lg={18} >
        {browserNotificationGranted === "granted" ? 
            "" : ( browserNotificationGranted === "default" ?
            <Button type="danger" onClick={() => enableBrowserNotification()}>Enable notification so we can tell you when people want to talk to you</Button>
            : "You denied the browser notification. If you want to be notified when someone calls you, please use non-incognito mode or grant notification to this website in browser setting!")}
        <br/><br/>
        
        <div className="mb-3">
            <Collapse expandIconPosition="right" ghost>
                <Collapse expandIconPosition="right" ghost>
                    {renderUser(me, -1, false)}
                </Collapse>       
            </Collapse>
        </div>

        {/* the rest of users */}
        {/* <h3>Other conference attendees (sorted by relevance)</h3> */}
        <Space direction="vertical">
            <Space wrap>
                {options.map((o, i) => <Button type="primary" size="large" style={{background: colors[i], borderColor: colors[i]}} onClick={searchSelected}>
                {o.value(introSelect(), similiarSelect(), topicInput())}
            </Button>)}
            
            </Space>
        </Space>

        <AutoComplete
            style={{minWidth: "600px"}}
            options={[{value: typingQuery}]} //{options.filter(o => o.value == typingQuery).length ? options:[{value: typingQuery}].concat(options)}
            onSelect={onSearch}
            onChange={onSearchChange}
            value={typingQuery}
            >
            <Input.Search size="large"  onPressEnter={onEnter} prefix={searchLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />} maxLength={500} placeholder="Search researchers based on their research interest or expertise" enterButton allowClear/>
        </AutoComplete>
        <br/>

        {searchQuery && <span>{users.length == originalUsers.length ? "Sorted by":"Search results of"} "{searchQuery}" <Button type="text" style={{cursor: "pointer", textDecoration:"underline"}} onClick={(e) => onSearchClear()}><span style={{textDecoration:"underline"}}>Clear results</span></Button></span>}

        {/* people who are on wishlist 
        {/*<h3>Bookmarked people to talk to</h3>*/}
        <div className="mb-3">    
            {!talkEnabled && !searchQuery && (wishUsers.length > 0 &&
                <Collapse expandIconPosition="right" ghost>
                    {sortUsers(wishUsers)}
            </Collapse>)}
        </div>  

        {/* search names */}
        {/* comment out for later dev */}
        {/* <Search
            placeholder="Search by name, interest, institute etc"
            className="mb-3"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={(v) => filterUsers(v)}
        />
        <br/> */}

        { searchQuery ?
            (isInvalidSearch ? <Empty description={<span>We can only answer questions about researchers' expertise and interest registered on Who2chat. Try other queries!</span>}/>
                : (users.length > 0 ? <Collapse expandIconPosition="right" ghost>
                    {sortUsers(users)}
                </Collapse>: (
                    me['prev_papers'] && Object.keys(me['prev_papers']).length > 0 ? <Empty description={searchError['NO_MATCH']}/>:<Empty description={searchError['NO_EXPERTISE']}/>)) )
            : (talkEnabled ?
                // During coffee hour, show online people at the top
                Object.values(statusList).filter(v => v.state == "online").length > 0 && 
                <Collapse expandIconPosition="right" ghost>
                {sortUsers( users.filter(user => Object.keys(statusList).filter((item) => {
                    return statusList[item].state === "online"
                    }).indexOf(user.id) >= 0 ) )}
                </Collapse>
                : users.filter(user => !(wishUsers.filter(u => u.id === user.id).length > 0)).length > 0 && <Collapse expandIconPosition="right" ghost>
                {sortUsers( users.filter(user => !(wishUsers.filter(u => u.id === user.id).length > 0)) )}
                </Collapse>)
        }

        {/* During coffee hour when there is no search, put offline users at the bottom */}
        {talkEnabled && !searchQuery && 
            Object.values(statusList).filter(v => v.state == "offline").length > 0 && 
            <Collapse expandIconPosition="right" ghost>
            {sortUsers( users.filter(user => Object.keys(statusList).filter((item) => {
                return statusList[item].state === "offline"
                }).indexOf(user.id) >= 0 ) )}
            </Collapse>}

        <Modal title={`Send suggestion to meet ${'name' in currentSuggestionUser ? currentSuggestionUser.name : ""}`} visible={isModalVisible} 
            onOk={e => {e.stopPropagation();handleOk(e)}} onCancel={e => {e.stopPropagation();handleCancel()}}>
            <Form
                name="basic"
                form={suggestionForm}
            >
                <Form.Item
                    label="To"
                    name="name"
                    rules={[{ required: true, message: 'Please input recipients!' }]}
                >
                <Select
                    mode="multiple"
                    allowClear
                    labelInValue
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    filterOption={(input, option) =>
                        // handling option.children.toLowerCase() error  
                        option.children ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0:false
                     }
                    onChange={(e)=> {
                        let recipients = [];

                        // add recipients from group
                        e.filter(p => p.value.startsWith("group-")).map((p) => {
                            recipients.push( ...relationships[p.value.split("group-")[1]].map((group_member) => users.filter(u => u.id === group_member)[0] ) )
                        });

                        recipients.push(...e.filter(p => !p.value.startsWith("group-")).map((p) => users.filter(u => u.id === p.value)[0] ));

                        // remove the redundant users if any
                        recipients = recipients.reduce((unique, o) => {
                            if(!unique.some(obj => obj.id === o.id)) {
                              unique.push(o);
                            }
                            return unique;
                        },[]);

                        setSuggestionRecipients(recipients); 
                    }}>
                    {Object.keys( relationships ).filter(r => relationships[r].length).map((r) => 
                        <Option key={"group-" + r}>
                            <UsergroupAddOutlined /> {r} ({relationships[r].length} people)
                        </Option>
                    )}
                    {users.filter(u => u.id != currentSuggestionUser.id) // exclude the suggested user
                        .map(u => <Option key={u.id}>{u.name}</Option>)}
                </Select>
                </Form.Item>
                <Form.Item
                    label="What they should talk about"
                    name="topics"
                    rules={[{ required: true, message: 'Please input topics!' }]}
                >
                    <Input onChange={(e) => setSuggestionTopics(e.target.value)} placeholder={`Ask ${currentSuggestionUser.name} about ..`} />
                </Form.Item>
          </Form>
        </Modal>
    </Col>:
    <Spin size="large" />
    }

    <Modal title="Leave a message" 
        visible={isSendingModalVisible} 
        onOk={() => setIsSendingModalVisible(false)} 
        onCancel={() => setIsSendingModalVisible(false)}
        footer={
            <Button type="primary" onClick={() => sendMessage()}>Send Message</Button>
        }
    >
        <TextArea rows={4} placeholder="Hi, I would love to have a chat with you! Please call me once you are available." onChange={handleChange} value={message}/>
    </Modal>

    <BuddyCall isBuddyVisible={isBuddyVisible} setIsBuddyVisible={setIsBuddyVisible} />

    </Row>
    );
}

export default People;
